export const icons = [
    {
        id: 1,
        name: "Home",
        image: "/assets/images/desktop-comp.png",
        link: "#",
        external: true
    },
    {
        id: 2,
        name: "Resume",
        image: "/assets/images/resume.png",
        link: "/resume",
        external: false
    },
    {
        id: 3,
        name: "Projects",
        image: "/assets/images/project.png",
        link: "/sciencestuff",
        external: false
    },
    {
        id: 4,
        name: "Blog",
        image: "/assets/images/blog.png",
        link: "/blog",
        external: false
    },
    {
        id: 5,
        name: "Gmail",
        image: "/assets/images/gmail.png",
        link: "mailto:ezeakudoluchinemerem@gmail.com",
        external: true
    },
    {
        id: 6,
        name: "LinkedIn",
        image: "/assets/images/linkedin.png",
        link: "https://www.linkedin.com/in/david-ezeakudolu-11003117b/",
        external: true
    },
    {
        id: 7,
        name: "Github",
        image: "/assets/images/github.png",
        link: "https://github.com/ChinemeremEze/",
        external: true
    },
    {
        id: 8,
        name: "Podcasts",
        image: "/assets/images/podcast.png",
        link: "https://benchandbleachers.buzzsprout.com",
        external: true
    },
    {
        id: 9,
        name: "Instagram",
        image: "/assets/images/instagram.png",
        link: "https://www.instagram.com/stigmontage",
        external: true
    },
    {
        id: 10,
        name: "Twitter",
        image: "/assets/images/twitter.png",
        link: "https://twitter.com/stigezehjunior",
        external: true
    },
    {
        id: 11,
        name: "Youtube",
        image: "/assets/images/youtube.png",
        link: "https://www.youtube.com/channel/UCGlpGu6aw3rb9n4ZXNDknQA",
        external: true
    },
    {
        id: 12,
        name: "Pinterest",
        image: "/assets/images/pinterest.png",
        link: "https://www.pinterest.com/stigmontage/",
        external: true
    },
    {
        id: 13,
        name: "Notes",
        image: "/assets/images/notes.png",
        link: "#",
        external: false
    },
    {
        id: 14,
        name: "Photos",
        image: "/assets/images/photos.png",
        link: "/pictures",
        external: false
    },
    {
        id: 15,
        name: "Videos",
        image: "/assets/images/videos.png",
        link: "/movies",
        external: false
    },
    {
        id: 16,
        name: "Music",
        image: "/assets/images/playlist.png",
        link: "https://music.apple.com/profile/ezeakudoluchinemerem",
        external: true
    },
    {
        id: 17,
        name: "IMDB",
        image: "/assets/images/movies.png",
        link: "https://www.imdb.com/search/title/?groups=top_100&sort=user_rating,desc",
        external: true
    },
    {
        id: 18,
        name: "Help",
        image: "/assets/images/donate.png",
        link: "https://www.globalgiving.org/",
        external: true
    },
    {
        id: 19,
        name: "Bible",
        image: "/assets/images/bible.png",
        link: "https://www.bible.com",
        external: true
    },
]

export const movies = [
 
    {
        id: 0,
        link: "https://www.youtube.com/embed/KyfCQC29ShA",
        title: "Ray-Ban",
        creator: "Stig"
    },
    {
        id: 1,
        link: "https://www.youtube-nocookie.com/embed/oGVhOWqsBWM",
        title: "The Tragedy of Goodluck",
        creator: "Pursuit of Wonder"
    },
    {
        id: 2,
        link: "https://www.youtube.com/embed/d2lmSjJ7-9o",
        title: "Chester cried watching Forest Gump",
        creator: "Chinemerem"
    },
    {
        id: 3,
        link: "https://www.youtube.com/embed/F5KgsubkMCA",
        title: "SURFIN'",
        creator: "Kid Cudi"
    },
    {
        id: 4,
        link: "https://www.youtube-nocookie.com/embed/JdwyAcJ8j2U",
        title: "Raymond K. Most Beautiful Day",
        creator: "Jack's Smirking Revenge"
  
    },
    {
        id: 5,
        link: "https://www.youtube.com/embed/juphxwgGecc",
       // link: "https://www.youtube.com/embed/xYHFM_0jWMI?start=7",
        title: "The Art of Observation",
        creator: "Stig"
    },
    {
        id: 6,
        link: "https://www.youtube.com/embed/ze7CiKeVxe0",
        title: "Drug Problems",
        creator: "Dave Chappelle"
    },

    {
        id: 7,
        link: "https://www.youtube.com/embed/Xik__RbHEO8",
        title: "Gabriella And Timi's 21st",
        creator: "Stig"
    },
    {
        id: 8,
        link: "https://www.youtube.com/embed/M850-7bqFhk",
        title: "Champions x2",
        creator: "Chelsea"
    },
    {
        id: 9,
        link: "https://www.youtube.com/embed/m-V8cZvAiJo",
        title: "Never take a risk", 
        creator: "Elon Musk"
    },
    {
        id: 10,
        link: "https://www.youtube.com/embed/hIdsjNGCGz4?start=7",
        title: "My Boy's Wicked Smart",
        creator: "Matt Damon"
    },
    {
        id: 11,
        link: "https://www.youtube.com/embed/7eh4d6sabA0",
        title: "Machine Learning",
        creator: "Mosh"
    },
    {
        id: 12,
        link: "https://www.youtube.com/embed/usnznJZ0XvA",
        title: "Colonial Mentality",
        creator: "Fela Kuti"
    },
    {
        id: 13,
        link: "https://www.youtube.com/embed/Npvec_0c-DA?start=15",
        title: "The Universe is yours",
        creator: "Rick and Morty"
    },
    {
        id: 14,
        link: "https://www.youtube.com/embed/8Nael8xcSus?start=5",
        title: "",
        creator: ""
    }
]

export const scienceProjects = [
    {
        id: 1,
        title: "Chess", 
        link: "https://chess-clone.firebaseapp.com/",
        rating: 6,
        difficulty: 8,
        keywords: ["React", "webRTC", "Node.js", "Express", "Socket.io", "Online Game", "Multi Player"],
        description: "Multiplayer Chess Game built with React and Node.js + Express. Users can play their friends anonymously via link. Users are also able to chat with each other during the game via camera + microphone.",
        githubLink: "https://github.com/ChinemeremEze/chess"
    },
    {
        id: 2,
        title: "Deep Learning", 
        rating: 7,
        difficulty: 6,
        keywords: ["Python", "Numpy", "Multi Layer Perceptrons", "Machine Learning/Artifical Inteligence"],
        description: "For this project, I explored the use of perceptrons and multi-layer perceptrons on both artificial and real data sets.",
    },
    {
        id: 3,
        title: "Dr. Stig", 
        rating: 9,
        difficulty: 5,
        keywords: ["Python", "Numpy", "Multi Layer Perceptrons", "ML algorithms", "UCI machine learning repository", "Machine Learning", "Artifical Inteligence"],
        description: "Using data sets from the UCI archive I designed a program that predicted peoples illness based on previous test data and multiple deciding factors like Heart rate, Age, Blood pressure, Blood type etc, Algorithms present inlcudes, Manhattan Distance, Clustering, K-Nearest Neighbour. and few others",
    },
    {
        id: 4,
        title: "Giftcoin", 
        rating: 8,
        difficulty: 7,
        keywords: ["Cryptocurrency", "Web Application", "Node.js", "Express", "React", "External APIs", "Account Creation", "Payment Integration"],
        description: "A web application were users can trade out their crypto currencies for gift cards in minuties, The user chooses a gift card and amount, an accurate amount in crypto currencies would be charged, when the transfer is credited a gift card with the corresponding amount is sent to the users' e-mail",
        githubLink: "https://github.com/ChinemeremEze/GiftCoin"
    },
    {
        id: 5,
        title: "ISAC", 
        rating: 6,
        difficulty: 6,
        keywords: ["PHP", "Javascript", "Team Member", "Web Application", "Sensor Technology", "API integration"],
        description: "On this project myself an my team members attemped to replace the use of lazer technology in airplane wing alignments with sensor technology. The web application displayed and managed the data derived from the sensors activity using PHP and Javascript, other information about this project is disclosed.",
    },

    {
        id: 6,
        title: "Grades Dashboard", 
        rating: 5,
        difficulty: 7,
        keywords: ["Vue ", "Web Application", "Socket IO"],
        description: "An application using Vue.js which allows an instructor to manage and analyze a list of grades associated with courses. The functionality should be implemented using Vue.js in the expected ways (e.g. using templates, directives, Vue instance data, computed properties, etc.).",
        githubLink: "https://github.com/ChinemeremEze/gradesDashboard"
    },
    {
        id: 7,
        title: "React Ecosystem TODO List Application", 
        rating: 6,
        difficulty: 4,
        keywords: ["React ", "Redux", "Thunk", "Reselect", "Styled componenets"],
        description: "One of the most common ways to test your web development skills is developing a todo list, This project is complex react app todo list that uses major react libraries like redux, thunk, reselect, styled component and testing ",
        githubLink: "https://github.com/ChinemeremEze/todo-with-react-ecosystem"
    },
    {
        id: 8,
        title: "Image Quantization", 
        rating: 7,
        difficulty: 5,
        keywords: ["Python ", "Numpy", "Artifical Inteligence", "Machine Learning Algorithms"],
        description: "Using python and numpy I used the k-means clustering algorithm to perform a color quantization of an image. Clustering algorithms analyze a set of data and break it into clusters(groups) of similar items. Clustering algorithms are widely used for big data analysis. eg Netflix. Here Clustering was used to quantisize the colors of an Image.",
    },
    
    {
        id: 9,
        title: "chikaluxury", 
        link: "https://chikaluxury.com",
        rating: 8,
        difficulty: 3,
        keywords: ["E-commerce", "Shopify", "Luxury"],
        description: "Chikaluxury is an ecommerce website built with shopify, designed using spotify themes and personal edits to look as desired, Chikaluxury is a drop shipping site for women luxurious items",
    },
    {
        id: 10,
        title: "MMDB", 
        link: "https://chinemeremeze.github.io/mmdb",
        rating: 7,
        difficulty: 3,
        keywords: ["React ", "Web Application", "Probably Illegeal", "Learning Purposes Only", "Movies", "TV Shows", "Bootstrap"],
        description: "Search and watch your favourite movies. React project using react-router-dom, usecontext and many other advanced concepts ",
        githubLink: "https://github.com/ChinemeremEze/mmdb"
    },
    
]

export const titles = [
    {
        id: 1,
        name: "Chinemerem",
    },
    {
        id: 2,
        name: "Ezeakudolu",
    },
    {
        id: 3,
        name: "Software Engineer",
    },
    {
        id: 4,
        name: "Film Director",
    },
    {
        id: 5,
        name: "Christian",
    },
    {
        id: 6,
        name: "Stig",
    },
    {
        id: 7,
        name: "Scientist",
    },
    {
        id: 8,
        name: "Model",
    },
    {
        id: 9,
        name: "Stig",
    },
    {
        id: 10,
        name: "Fire Fighter",
    },
    {
        id: 11,
        name: "Chukwuma",
    },
    {
        id: 11,
        name: "Tech Consultant",
    },
    {
        id: 12,
        name: "Founder",
    },
]

export const projects = [
    {
        id: 1,
        title: "Bonde",
        description: "Founder of Bonde, a portfolio-sharing platform designed to empower artists by enabling them to showcase their work, connect with opportunities, and monetize their craft. Bonde offers a comprehensive suite of features, including the ability to post and apply for jobs, buy and sell artwork, and manage event ticket sales. Basically LinkedIn on steriods for artists",
        link: "https://www.instagram.com/we.bonde" ,
        category: "Founder",
        year: "2024"       
    },
    {
        id: 2,
        title: "Chika AI",
        description: "Co Founder of Chika AI, an AI-powered talent sourcing platform revolutionizing recruitment by automating candidate discovery and engagement. Chika AI simplifies hiring for businesses by using an intuitive intake form to understand job requirements, then identifying and connecting with top candidates. Interested candidates are delivered directly to clients' inboxes for easy review and approval ",
        link: "https://chika.ai",
        category: "Co-Founder",
        year: "2024"  
    },
    {
        id: 3,
        title: "CloudGenius",
        description: "As Co-founder of Cloud Genius, a tech consulting company specializing in cloud, DevOps, and web technologies, my role is multifaceted. I work closely with clients to advise, analyze, and implement tailored software solutions that meet their specific needs. Additionally, I oversee the development and maintenance of Cloud Genius's own web application, ensuring its robustness and usability. A significant part of my role also involves training and mentoring students and trainees, equipping them with hands-on skills in web development and guiding them to excel in the evolving tech landscape. This dual focus on client service and skill-building drives our mission to foster growth and innovation in tech.",
        link: "https://cloudgenius.ca" ,
        category: "Co-Founder",
        year: "2022"         
    },
    {
        id: 4,
        title: "Crossroads",
        description: "Currently serving as the Lead Software Engineer at Crossroads, leading the development of their latest project, AnyonePray. This SaaS platform facilitates 24/7 prayer call management for Christian organizations, enhancing their ability to support congregations effectively. AnyonePray is recognized as Canada’s oldest and largest prayer line .",
        link: "https://crossroads.ca/"  ,
        category: "Lead Software Engineer",
        year: "2022"        
    },
    {
        id: 5,
        title: "UTTERANCE",
        description: "This is currently under construction, well development hasn't started but I constantly day dream and fantasize about the idea therfore much thought has been put into this, This would be very beneficial to the general public so maybe pressure me into putting more effort into it or assist in buying the domain utterance.com on godaddy. ",
        link: "https://www.afternic.com/forsale/UTTERANCE.COM?utm_source=TDFS&utm_medium=sn_affiliate_click&utm_campaign=TDFS_GoDaddy_DLS&traffic_type=TDFS&traffic_id=GoDaddy_DLS",
        category: "Web Application",
        year: "2023"  
    },
    {
        id: 6,
        title: "Stig Montage",
        description: "A set of related web pages located under a single domain name, typically produced by a single person or organization in this case me, stigmontage.com is a platform to showcase/display his ideas, projects and his overall essence.",
        link: "https://stigmontage.com",
        category: "Web Design",
        year: "2020"        
    },
    {
        id: 7,
        title: "Deep Learning AI",
        description: "As part of a school project during my time at DeepLearning.AI, I developed a state-of-the-art computer vision model for analyzing MRI scans. The model detects the presence of tumors and identifies their precise location using advanced image segmentation techniques.",
        link: "https://github.com/ChinemeremEze/ai-projects/blob/master/brain-tumor-segmentation-using-MRI.ipynb"  ,
        category: "Project",
        year: "2022"        
    },
]